// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {f4fAWgZ0Z: {hover: true}};

const cycleOrder = ["f4fAWgZ0Z", "nLxKgpJEt"];

const serializationHash = "framer-g7h9L"

const variantClassNames = {f4fAWgZ0Z: "framer-v-qyr0qg", nLxKgpJEt: "framer-v-1sgy89n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {duration: 0, type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "f4fAWgZ0Z", "Variant 2": "nLxKgpJEt"}

const getProps = ({height, hover, id, tap, title, width, ...props}) => { return {...props, mln7gZMCD: tap ?? props.mln7gZMCD, oXsf8XfoD: title ?? props.oXsf8XfoD ?? "Powered by Moody Conversations", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "f4fAWgZ0Z", YJIqwhplY: hover ?? props.YJIqwhplY} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;hover?: any;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, oXsf8XfoD, YJIqwhplY, mln7gZMCD, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "f4fAWgZ0Z", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaplsjykf = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (mln7gZMCD) {
const res = await mln7gZMCD(...args);
if (res === false) return false;
}
})

const onMouseEnter1hwciqp = activeVariantCallback(async (...args) => {
if (YJIqwhplY) {
const res = await YJIqwhplY(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-qyr0qg", className, classNames)} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"f4fAWgZ0Z"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1hwciqp} onTap={onTaplsjykf} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 60, borderBottomRightRadius: 60, borderTopLeftRadius: 60, borderTopRightRadius: 60, ...style}} variants={{"f4fAWgZ0Z-hover": {backgroundColor: "rgba(212, 212, 212, 0.06)"}, nLxKgpJEt: {backgroundColor: "rgba(212, 212, 212, 0.06)"}}} {...addPropertyOverrides({"f4fAWgZ0Z-hover": {"data-framer-name": undefined}, nLxKgpJEt: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0dlaXN0IE1lZGl1bQ==", "--framer-font-family": "\"Geist Medium\", \"Geist Medium Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Powered by Moody Conversations</motion.p></React.Fragment>} className={"framer-yxxqos"} fonts={["CUSTOM;Geist Medium"]} layoutDependency={layoutDependency} layoutId={"YltFbs7eb"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", opacity: 0.5}} text={oXsf8XfoD} variants={{"f4fAWgZ0Z-hover": {opacity: 1}, nLxKgpJEt: {opacity: 1}}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-g7h9L[data-border=\"true\"]::after, .framer-g7h9L [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-g7h9L.framer-1srr18d, .framer-g7h9L .framer-1srr18d { display: block; }", ".framer-g7h9L.framer-qyr0qg { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 12px 24px 12px 24px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-g7h9L .framer-yxxqos { flex: none; height: auto; position: relative; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-g7h9L.framer-qyr0qg { gap: 0px; } .framer-g7h9L.framer-qyr0qg > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-g7h9L.framer-qyr0qg > :first-child { margin-top: 0px; } .framer-g7h9L.framer-qyr0qg > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38.5
 * @framerIntrinsicWidth 240.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"nLxKgpJEt":{"layout":["auto","auto"]},"JSxcbRrr4":{"layout":["auto","auto"]}}}
 * @framerVariables {"oXsf8XfoD":"title","YJIqwhplY":"hover","mln7gZMCD":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FrameroJI6vI_zt: React.ComponentType<Props> = withCSS(Component, css, "framer-g7h9L") as typeof Component;
export default FrameroJI6vI_zt;

FrameroJI6vI_zt.displayName = "Powered By Badge Copy";

FrameroJI6vI_zt.defaultProps = {height: 38.5, width: 240.5};

addPropertyControls(FrameroJI6vI_zt, {variant: {options: ["f4fAWgZ0Z", "nLxKgpJEt"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, oXsf8XfoD: {defaultValue: "Powered by Moody Conversations", displayTextArea: false, title: "Title", type: ControlType.String}, YJIqwhplY: {title: "Hover", type: ControlType.EventHandler}, mln7gZMCD: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FrameroJI6vI_zt, [{family: "Geist Medium", url: "https://framerusercontent.com/assets/dNqH0c4qjo3RGmYZnJknwKtT0.woff2"}])